import React, { FC, ReactNode } from 'react';
import { Arrow } from '../../images/arrow/Arrow';

import { Wrapper, HeaderText, Container, ContentText, ArrowWrapper, TextWrapper } from './styles';

export interface ExpandedRowProps {
    header: string | ReactNode;
    onExpand?: () => void;
    onCollapse?: () => void;
    isFirst?: boolean;
}

export const ExpandedRow: FC<ExpandedRowProps> = ({ header, children, onExpand, onCollapse, isFirst = false }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    const handleExpand = React.useCallback(() => {
        setIsExpanded(!isExpanded);

        if (onExpand && !isExpanded) {
            onExpand();
        }

        if (onCollapse && isExpanded) {
            onCollapse();
        }
    }, [isExpanded, onCollapse, onExpand]);

    return (
        <Container isFirst={isFirst}>
            <Wrapper onClick={handleExpand}>
                {typeof header === 'string' ? <HeaderText typography="body-long-xl">{header}</HeaderText> : header}
                <ArrowWrapper isExpanded={isExpanded}>
                    <Arrow />
                </ArrowWrapper>
            </Wrapper>
            <TextWrapper $isExpanded={isExpanded}>
                <ContentText typography="body-long-xl">{children}</ContentText>
            </TextWrapper>
        </Container>
    );
};
