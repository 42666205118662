export const idCounter = 86937878;

export enum QRGoals {
    DownloadSimpleQR = 'downloadSimpleCreatedQR',
    DownloadCustomQR = 'downloadCustomCreatedQR',
}

export enum QRVisitParams {
    ChangeYQrId = 'isCheckedYQrId',

    ClickOnTab = 'clickOnTab',

    ReadingFAQ = 'readingFAQ',

    ClickOnHelpAboutQR = 'clickOnHelpAboutQR',

    ChangeQRLogo = 'changeQRLogo',

    OpenSettingsQR = 'openSettingsQR',
}

export enum EventDownloadByButton {
    ClickOnJPEGSaveButton = 'clickOnJPEGSaveButton',
    ClickOnPNGSaveButton = 'clickOnPNGSaveButton',
    ClickOnSVGSaveButton = 'clickOnSVGSaveButton',
}

export enum EventReadingFAQ {
    WhatIsQR = 'whatIsQr',
    HowScanQR = 'howScanQR',
    HowCreateQR = 'howCreateQR',
    WhereUseQR = 'whereUseQR',
}

export enum EventReadingFAQState {
    Expanded = 'expand',
    Collapsed = 'collapse',
}

export enum EventOpenSettingsQR {
    IsExpandedSettings = 'isExpandedSettings',
}

export enum CounterColorProperty {
    ChangeForegroundColor = 'changeForegroundColorPicker',
    ChangeBackgroundColor = 'changeBackgroundColorPicker',
}

export interface CounterSettings {
    changeQRLogo: boolean;
    [CounterColorProperty.ChangeForegroundColor]: boolean;
    [CounterColorProperty.ChangeBackgroundColor]: boolean;
    [key: string]: boolean;
}

export const isCustomQR = (counter: CounterSettings) => {
    for (let item in counter) {
        if (counter[item]) {
            return true;
        }
    }
    return false;
};

export const reachGoal = (goal: QRGoals, params?: any) => {
    ym(idCounter, 'reachGoal', goal);
};

export const sendParams = (params: any) => {
    ym(idCounter, 'params', params);
};
