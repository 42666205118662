import React, { useRef } from 'react';
import debounce from 'lodash.debounce';

const minDesktopWidth = 700;
const debounceDelay = 200;

const isMobileResolution = (resolution: number) => {
    return window.innerWidth < resolution;
};

const isChangedMobileResolution = (currentWindowResolution: number) => {
    return currentWindowResolution !== window.innerWidth;
};

export const useIsMobile = (resolution = minDesktopWidth): [boolean, number] => {
    const [isMobile, setIsMobile] = React.useState<boolean>(isMobileResolution(resolution));
    const [windowResolution, setWindowResolution] = React.useState<number>(window.innerWidth);

    const resolutionRef = useRef(windowResolution);
    React.useEffect(() => {
        resolutionRef.current = windowResolution;
    });

    const handleResize = debounce(
        React.useCallback(() => {
            if (isChangedMobileResolution(resolutionRef.current)) {
                const setter = window.innerWidth;
                setWindowResolution(setter);
            }

            setIsMobile(isMobileResolution(resolution));
        }, [resolution]),
        debounceDelay,
    );

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [isMobile, windowResolution];
};
