import styled from 'styled-components';

export const QrPreviewWrapper = styled.div<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? `240px` : `312px`)};
    height: ${({ isMobile }) => (isMobile ? `240px` : `256px`)};
    border-radius: ${({ isMobile }) => (isMobile ? `46px` : `24px`)};
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-clip: padding-box;
    padding: ${({ isMobile }) => (isMobile ? `20px` : `28px`)};
`;

export const Image = styled.img<{ isMobile: boolean; isVisible: boolean }>`
    height: 100%;
    width: ${({ isMobile }) => (isMobile ? `240px` : `256px`)};

    opacity: ${({ isVisible }) => (isVisible ? `1` : `0`)};
    transition: opacity 0.3s;
`;
