import styled from 'styled-components';
import { Text } from '@yandex-lego/components/Text/bundle';
import { Drawer } from '@yandex-lego/components/Drawer/touch-phone/bundle';

export const ModalWrapper = styled.div<{ isVisible: boolean }>`
    position: absolute;
    top: 0;
    right: -600px;
    right: ${({ isVisible }) => isVisible && `0`};
    transition: right 0.3s ease-in-out;

    z-index: 20;

    width: 600px;

    background-color: white;
    height: 100%;

    filter: drop-shadow(0px 10px 20px rgba(165, 177, 202, 0.3)) drop-shadow(0px 3px 6px rgba(165, 177, 202, 0.3));
`;

export const Overlay = styled.div<{ isVisible: boolean }>`
    position: absolute;
    top: 0;
    right: 0;

    height: 100%;
    width: 100%;

    opacity: ${({ isVisible }) => (isVisible ? `1` : `0`)};
    transition: opacity 0.3s ease-in-out;

    z-index: 20;
    visibility: ${({ isVisible }) => !isVisible && `hidden`};

    background: #ffffff33;
    backdrop-filter: blur(8px);
`;

export const InfoHeader = styled.div`
    position: relative;
    background-color: #eff2f7;
    padding: 20px 32px;
`;

export const HeaderText = styled(Text)<{ $isMobile?: boolean }>`
    line-height: ${({ $isMobile }) => ($isMobile ? `28px` : `32px`)};
    color: #2f3747;
    overflow-wrap: break-word;

    width: ${({ $isMobile }) => $isMobile && `100%`};
`;

export const ClearBackground = styled.div<{ isVisible: boolean }>`
    position: absolute;
    top: 14px;
    left: -22px;
    width: 44px;
    height: 44px;
    border-radius: 24px;
    z-index: 4;

    background-color: #ffffff;
    filter: drop-shadow(0px 10px 20px rgba(165, 177, 202, 0.3)) drop-shadow(0px 3px 6px rgba(165, 177, 202, 0.3));

    display: ${({ isVisible }) => (isVisible ? `flex` : `none`)};
    align-items: center;
    justify-content: center;

    cursor: pointer;
`;

export const RelativeWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`;

export const ContentWrapper = styled.div`
    overflow-y: auto;
    height: 100%;
    width: 100%;
`;

export const HeaderWrapper = styled.div`
    display: flex;

    padding: 16px 10px 0 20px;
`;

export const MobileDrawer = styled(Drawer)<{ isFullScreen: boolean }>`
    & .Drawer-Curtain {
        margin-top: 52px;
        height: ${({ isFullScreen }) => isFullScreen && `100%`};
    }

    & .Drawer-Content {
        height: ${({ isFullScreen }) => isFullScreen && `100%`};
    }

    & .Drawer-Overlay {
        background-color: rgba(47, 55, 71, 0.8);
    }
`;
