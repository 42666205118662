import React from 'react';
import { RadioTabs } from './styles';
import { useContext } from 'react';
import { QrContext } from '../../App';

interface TabsProps {
    value: string;
    onChange: (evt: any) => void;
}

export enum TabsNames {
    Colors = 'colors',
    Icons = 'icons',
    // Design = 'design',
    // Frame = 'frame',
}

export const Tabs = ({ value, onChange }: TabsProps) => {
    const { isMobile } = useContext(QrContext);

    const options = [
        { value: TabsNames.Icons, children: 'Иконка' },
        { value: TabsNames.Colors, children: 'Цвета' },
        // { value: TabsNames.Design, children: 'Дизайн' },
        // { value: TabsNames.Frame, children: 'Рамка' },
    ];

    return (
        <RadioTabs
            size={!isMobile ? 'l' : 'm'}
            view="default"
            value={value}
            options={options}
            onChange={onChange}
            $isMobile={isMobile}
        />
    );
};
