import { BodyWrapper, GlobalStyle, Content } from './styles';
import React, { FC } from 'react';
import { Footer } from '../Footer/Footer';
import { bodyWrapperId } from '../../constants/QRPageConstans';
import { configureRootTheme } from '@yandex-lego/components/Theme';
import { theme } from '@yandex-lego/components/Theme/presets/default';

configureRootTheme({ theme });

export interface LayoutProps {
    isMobile: boolean;
}

export const Layout: FC<LayoutProps> = ({ isMobile, children }) => {
    const [height, setHeight] = React.useState(window.innerHeight);

    const handleResize = React.useCallback(() => {
        setHeight(window.innerHeight);
    }, []);

    React.useEffect(() => {
        if (isMobile) {
            window.addEventListener('resize', handleResize);
        }

        return () => {
            document.removeEventListener('resize', handleResize);
        };
    }, [isMobile]);

    return (
        <>
            <GlobalStyle isMobile={isMobile} height={height} />
            <BodyWrapper isMobile={isMobile} id={bodyWrapperId}>
                <Content isMobile={isMobile}>{children}</Content>
                <Footer isMobile={isMobile} />
            </BodyWrapper>
        </>
    );
};
