import React, { ReactElement } from 'react';
import { Portal } from '@yandex-lego/components/Portal';

interface QRPortalProps {
    parentBlockId: string;
    children: ReactElement;
}

export const QRPortal = ({ children, parentBlockId }: QRPortalProps) => {
    const parentBoxRef = React.useRef<HTMLElement | null>(document.getElementById(parentBlockId));

    return (
        <Portal visible scope={parentBoxRef}>
            {children}
        </Portal>
    );
};
