import styled from 'styled-components';
import { Text } from '@yandex-lego/components/Text/bundle';

export const PreviewLogo = styled.div`
    position: relative;
    width: 116px;
    height: 116px;
    border-radius: 10px;
    flex-shrink: 0;
    background-color: #ffffff;
`;

export const LogoImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
`;

export const ClearContainer = styled.div`
    position: absolute;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    top: 0;
    left: 0;
    margin-top: 10px;
    margin-left: 86px;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 50%;
`;

export const Wrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: row;
    margin-top: 28px;
    margin-bottom: 8px;
`;

export const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 13px;
`;

export const ImageName = styled(Text)`
    color: #2f3747;
`;

export const ImageResolution = styled(Text)`
    line-height: 22px;
    color: #1a2b4d99;
`;

export const HiddenInput = styled.input`
    display: none;
`;

export const LabelText = styled(Text)`
    color: #2f3747;
    line-height: 22px;
    border-bottom: 1px dashed #2f3747;
    cursor: pointer;
`;

export const ChangerImageWrapper = styled.label`
    margin-top: 16px;
`;
