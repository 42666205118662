import styled from 'styled-components';
import { Text } from '@yandex-lego/components/Text/bundle';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const LoaderContainer = styled.div<{ isHighlighted: boolean; isMobile: boolean }>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    border: 2px dashed rgba(134, 148, 176, 0.4);
    box-sizing: border-box;
    margin: ${({ isMobile }) => (isMobile ? `32px 0 12px 0;` : `28px 0 12px 0`)};
    padding: 24px 0;
    border-radius: 16px;

    background-color: ${({ isHighlighted }) => isHighlighted && `#B0BDD626`};
`;

export const InputLoader = styled.input`
    display: none;
`;

export const LoaderLabel = styled.label<{ isPressed: boolean; isMobile: boolean }>`
    margin-left: ${({ isMobile }) => (isMobile ? `0` : `8px`)};

    border-radius: 14px;
    padding: 10px 20px;
    cursor: pointer;

    background-color: #b0bdd633;
    transform: scale(1);

    transform: ${({ isPressed }) => isPressed && `scale(.96)`};
    transition: transform 0.1s ease-out;

    &:hover {
        background-color: #a5b1ca4d;
    }
`;

export const DropText = styled(Text)`
    color: #1a2b4d99;
`;

export const DescriptionText = styled(Text)`
    color: #475a8080;
`;

export const ButtonText = styled(Text)`
    color: #2f3747;
`;

export const ErrorText = styled(Text)<{ $isMobile: boolean }>`
    color: #dd0000;
    line-height: 22px;
    width: 100%;
    margin-top: ${({ $isMobile }) => !$isMobile && `16px`};
    margin-bottom: 16px;
    display: block;
`;
