import { SingleColorPicker, Wrapper } from './styles';
import React, { useContext } from 'react';
import { QrContext } from '../../App';

export interface ColorSettingsProps {
    currentForegroundColor: string;
    onChangeColor: (hex: string) => void;

    currentBackgroundColor: string;
    onChangeBackgroundColor: (hex: string) => void;

    scrollContainerId: string;
}

export const ColorSettings = ({
    currentForegroundColor,
    onChangeColor,
    currentBackgroundColor,
    onChangeBackgroundColor,

    scrollContainerId,
}: ColorSettingsProps) => {
    const { isMobile } = useContext(QrContext);

    return (
        <Wrapper isMobile={isMobile}>
            <SingleColorPicker
                id="colorForegroundBox"
                onChange={onChangeColor}
                color={currentForegroundColor}
                title="Цвет кода"
                scrollContainerId={scrollContainerId}
            />
            <SingleColorPicker
                id="colorBackgroundBox"
                onChange={onChangeBackgroundColor}
                color={currentBackgroundColor}
                title="Цвет фона"
                isMobile={isMobile}
                scrollContainerId={scrollContainerId}
            />
        </Wrapper>
    );
};
