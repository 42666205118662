import styled from 'styled-components';
import { Textinput } from '@yandex-lego/components/Textinput/desktop/bundle';
import { colorPickerHeight } from './ColorPicker';
import { Text } from '@yandex-lego/components/Text/bundle';

export const defaultStepColor = 100;

export const ColoredSliderBox = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: row;
    padding-right: 40px;
    margin-top: 13px;
`;

export const ColoredBox = styled.div<{ color: string }>`
    width: 44px;
    height: 44px;
    cursor: pointer;
    background-color: ${({ color }) => color || '#000'};

    border: 6px solid rgba(165, 177, 202, 0.3);
    box-sizing: border-box;
    border-radius: 14px;

    background-clip: padding-box;
`;

export enum Direction {
    Up,
    Down,
}

export const ColorPickerBox = styled.div<{ top: number; left: number; direction: Direction; isMobile: boolean }>`
    top: ${({ top, direction }) =>
        direction === Direction.Down ? `${top + 50}px` : `${top - colorPickerHeight - 10}px`};
    left: ${({ left }) => `${left}px`};
    position: ${({ isMobile }) => !isMobile && `absolute`};
    z-index: 4;
`;

export const SliderBox = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TextBox = styled.div`
    cursor: pointer;
`;

export const Input = styled(Textinput)`
    width: 120px;
    margin-left: 8px;

    & .Textinput-Box {
        width: 120px;
        height: 44px;
        border-radius: 14px;
        border-width: 1px;
    }

    & .Textinput-Control {
        width: 120px;
        height: 44px;

        font-size: 16px;
        line-height: 24px;

        padding: 10px 20px;
        border: 0;
    }
`;

export const TitleText = styled(Text)`
    color: #2f3747;
`;

export const MobilePickerWrapper = styled.div`
    padding: 20px;
`;
