import { QrPreviewWrapper, Image } from './styles';
import React, { useContext } from 'react';
import vkQr from '@vkontakte/vk-qr';
import { Logo } from '../../components/LogoPreview/LogoPreview';
import { customAlphabet } from 'nanoid';
import { qrSize } from '../../constants/QR';

import { EmptyQRBase64Value } from '../../images/emptyQRCode/EmptyQRCode';
import { yQrIdAlphabet, yQrId } from '../../constants/yQRID';
import { QrContext } from '../../App';
import { isValidUrl } from '../../helpers/isValidUrl/isValidUrl';

export interface PreviewProps {
    url: string;
    foregroundColor: string;
    backgroundColor: string;
    logo?: Logo;

    qrSVG?: string;
    onChangeQrSVG: (newQR: string) => void;

    isYQREnabled: boolean;
}

export const Preview = ({ url, foregroundColor, backgroundColor, logo, onChangeQrSVG, isYQREnabled }: PreviewProps) => {
    const nano = React.useCallback(customAlphabet(yQrIdAlphabet, 11), []);

    const containerRef = React.useRef<HTMLDivElement>(null);
    const imageRef = React.useRef<HTMLImageElement>(null);
    const [urlWithYQRid, setUrlWithYQRid] = React.useState('');

    const [isImageVisible, setIsImageVisible] = React.useState(false);

    const handleLoadImage = React.useCallback(() => {
        setIsImageVisible(true);
    }, []);
    const { isMobile } = useContext(QrContext);

    const hasProtocol = React.useCallback((url: string) => {
        return url.indexOf('://') >= 0;
    }, []);

    const getGeneratedUrl = React.useCallback(
        (url: string) => {
            let urlForGenerating = url.trim();

            if (!urlForGenerating) {
                return urlForGenerating;
            }

            if (isValidUrl(urlForGenerating)) {
                if (!hasProtocol(urlForGenerating)) {
                    urlForGenerating = `http://${urlForGenerating}`;
                }

                if (isYQREnabled && urlForGenerating.indexOf(yQrId) < 0) {
                    const generatedUuid = nano();
                    const queryPosition = urlForGenerating.indexOf('?');

                    if (queryPosition >= 0) {
                        urlForGenerating +=
                            urlForGenerating.slice(queryPosition + 1) !== ''
                                ? `&${yQrId}=${generatedUuid}`
                                : `${yQrId}=${generatedUuid}`;
                    } else {
                        urlForGenerating += `?${yQrId}=${generatedUuid}`;
                    }
                }
            }

            return urlForGenerating;
        },
        [hasProtocol, isYQREnabled, nano],
    );

    const changePreviewImage = React.useCallback(
        (imageBase64: string) => {
            if (imageRef && imageRef.current) {
                imageRef.current.src = `data:image/svg+xml;base64,${imageBase64}`;
                imageRef.current.onload = () => {
                    onChangeQrSVG(`data:image/svg+xml;base64,${imageBase64}`);
                };
            }
        },
        [onChangeQrSVG],
    );

    const renderQR = React.useCallback(
        (url: string, backgroundColor: string, foregroundColor: string, logo?: Logo) => {
            if (!url) {
                changePreviewImage(EmptyQRBase64Value);
                return;
            }

            const qrSvg = vkQr.createQR(url, {
                qrSize: qrSize,
                isShowLogo: Boolean(logo?.logoBase64),
                logoData: logo?.logoBase64,
                isShowBackground: true,
                backgroundColor: `${backgroundColor}`,
                foregroundColor: `${foregroundColor}`,
            });

            const svgBase64 = btoa(qrSvg);

            if (imageRef && imageRef.current) {
                changePreviewImage(svgBase64);
            }
        },
        [changePreviewImage],
    );

    React.useEffect(() => {
        const generatedUrl = getGeneratedUrl(url);

        setUrlWithYQRid(generatedUrl);
    }, [getGeneratedUrl, url]);

    React.useEffect(() => {
        renderQR(urlWithYQRid, backgroundColor, foregroundColor, logo);
    }, [backgroundColor, foregroundColor, logo, renderQR, urlWithYQRid]);

    React.useEffect(() => {
        if (imageRef && imageRef.current) {
            imageRef.current.onload = handleLoadImage;
        }
    }, [handleLoadImage]);

    return (
        <QrPreviewWrapper isMobile={isMobile} ref={containerRef}>
            <Image ref={imageRef} isMobile={isMobile} isVisible={isImageVisible} />
        </QrPreviewWrapper>
    );
};
