import {
    CloseText,
    Container,
    ContentWrapper,
    ExpandedTitle,
    SettingsWrapper,
    TabsContentWrapper,
    TextContainer,
} from './styles';
import React, { FC, ReactNode, useContext } from 'react';
import { Tabs, TabsNames } from '../Tabs/Tabs';
import { ColorSettings } from '../ColorSettings/ColorSettings';
import { Logo } from '../LogoPreview/LogoPreview';
import { LogoErrors } from '../../validators/validationLogo/validationLogo';
import { ImagesDragAndDrop } from '../ImagesDragAndDrop/ImagesDragAndDrop';
import { QrContext } from '../../App';
import { EventOpenSettingsQR, QRVisitParams, sendParams } from '../../constants/Counter';

export interface ExpandedSettingsProps {
    foregroundColor: string;
    onChangeForegroundColor: (hex: string) => void;
    backgroundColor: string;
    onChangeBackgroundColor: (hex: string) => void;

    logo: Logo;
    onChangeLogo: (file: File | undefined) => void;

    logoError: Array<LogoErrors>;
    currentLoadedFileName: string;

    scrollContainerId: string;
}

export const ExpandedSettings: FC<ExpandedSettingsProps> = ({
    foregroundColor,
    backgroundColor,
    onChangeForegroundColor,
    onChangeBackgroundColor,
    logo,
    onChangeLogo,
    logoError,
    currentLoadedFileName,
    scrollContainerId,
}) => {
    const { isMobile } = useContext(QrContext);

    const [isExpanded, setIsExpanded] = React.useState(false);
    const [currentTab, setCurrentTab] = React.useState(TabsNames.Icons);

    const onChangeCurrentTab = (evt: any) => {
        setCurrentTab(evt.target.value);

        sendParams({
            [QRVisitParams.ClickOnTab]: evt.target.value,
        });
    };

    const sendExpandedSettingsParams = React.useCallback((paramsBody: any) => {
        sendParams({
            [QRVisitParams.OpenSettingsQR]: paramsBody,
        });
    }, []);

    const handleClickExpand = React.useCallback(() => {
        setIsExpanded(!isExpanded);

        sendExpandedSettingsParams({
            [EventOpenSettingsQR.IsExpandedSettings]: !isExpanded ? 'yes' : 'no',
        });
    }, [isExpanded, sendExpandedSettingsParams]);

    const handleClickClose = React.useCallback(() => {
        setIsExpanded(false);

        sendExpandedSettingsParams({
            [EventOpenSettingsQR.IsExpandedSettings]: 'no',
        });
    }, [sendExpandedSettingsParams]);

    const mapTabToChildren: Record<TabsNames, ReactNode> = {
        [TabsNames.Colors]: (
            <ColorSettings
                currentForegroundColor={foregroundColor}
                onChangeColor={onChangeForegroundColor}
                currentBackgroundColor={backgroundColor}
                onChangeBackgroundColor={onChangeBackgroundColor}
                scrollContainerId={scrollContainerId}
            />
        ),
        [TabsNames.Icons]: (
            <ImagesDragAndDrop
                currentLoadedFileName={currentLoadedFileName}
                errors={logoError}
                logo={logo}
                onChange={onChangeLogo}
            />
        ),
        // [TabsNames.Design]: isMobile ? <Plug /> : null,
        // [TabsNames.Frame]: isMobile ? <Plug /> : null,
    };

    return (
        <SettingsWrapper>
            <Container isExpanded={isExpanded} isMobile={isMobile}>
                <TextContainer onClick={handleClickExpand}>
                    <ExpandedTitle typography="body-long-xl" $isExpanded={isExpanded}>
                        Расширенные настройки
                    </ExpandedTitle>
                </TextContainer>
                {isExpanded && (
                    <TextContainer onClick={handleClickClose}>
                        <CloseText typography="body-long-xl" weight="light">
                            Скрыть
                        </CloseText>
                    </TextContainer>
                )}
            </Container>
            <ContentWrapper isExpanded={isExpanded}>
                <Tabs value={currentTab} onChange={onChangeCurrentTab} />
                <TabsContentWrapper isMobile={isMobile} isExpanded={isExpanded}>
                    {mapTabToChildren[currentTab]}
                </TabsContentWrapper>
            </ContentWrapper>
        </SettingsWrapper>
    );
};
