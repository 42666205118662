import React from 'react';

export const Remove = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM5.403 5.40289C5.79352 5.01237 6.42669 5.01237 6.81721 5.40289L10.0001 8.58579L13.183 5.40289C13.5735 5.01237 14.2067 5.01237 14.5972 5.40289C14.9877 5.79342 14.9877 6.42658 14.5972 6.81711L11.4143 10L14.5972 13.1829C14.9877 13.5734 14.9877 14.2066 14.5972 14.5971C14.2067 14.9876 13.5735 14.9876 13.183 14.5971L10.0001 11.4142L6.81721 14.5971C6.42669 14.9876 5.79352 14.9876 5.403 14.5971C5.01248 14.2066 5.01248 13.5734 5.403 13.1829L8.58589 10L5.403 6.81711C5.01248 6.42658 5.01248 5.79342 5.403 5.40289Z"
                fill="#2F3747"
            />
        </svg>
    );
};
