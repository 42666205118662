import { RadioButton } from '@yandex-lego/components/RadioButton/desktop/bundle';
import styled from 'styled-components';

export const RadioTabs = styled(RadioButton)<{ $isMobile: boolean }>`
    height: ${({ $isMobile }) => ($isMobile ? `40px` : `44px`)};
    min-height: ${({ $isMobile }) => ($isMobile ? `40px` : `44px`)};
    margin-top: 14px;
    border-radius: 14px;
    background-color: #b0bdd633;

    & .RadioButton-Radio_checked:before {
        border-radius: ${({ $isMobile }) => ($isMobile ? `10px` : `12px`)};
        height: ${({ $isMobile }) => ($isMobile ? `32px` : `38px`)};

        padding-top: ${({ $isMobile }) => $isMobile && `2px`};
    }

    & .RadioButton-Plate:before {
        border-radius: ${({ $isMobile }) => ($isMobile ? `10px` : `12px`)};
        height: ${({ $isMobile }) => ($isMobile ? `32px` : `38px`)};

        padding-top: ${({ $isMobile }) => $isMobile && `2px`};
    }

    & .RadioButton-Radio {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    width: 100%;
`;
