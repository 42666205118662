import {
    ColoredBox,
    ColoredSliderBox,
    ColorPickerBox,
    Direction,
    Input,
    SliderBox,
    TextBox,
    TitleText,
    MobilePickerWrapper,
} from './styles';
import React, { ChangeEvent, useContext } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { popupWrapperId } from '../../constants/QRPageConstans';
import { QRPortal } from '../QRPortal/QRPortal';
import { QrContext } from '../../App';
import { InfoDrawer } from '../InfoDrawer/InfoDrawer';
import MobileColorPicker from '../MobileColorPicker/MobileColorPicker';
import { ColorWrapChangeHandler } from 'react-color/lib/components/common/ColorWrap';

export interface ColorPickerProps {
    id: string;
    onChange: (hex: string) => void;
    color: string;
    isOpened?: boolean;

    title: string;

    className?: string;

    scrollContainerId: string;
}

export const colorPickerHeight = 300;

const formatAlphaToHex = (alpha?: number) => {
    const hexAlpha = typeof alpha !== 'undefined' ? Math.ceil(alpha * 255).toString(16) : '';

    return hexAlpha.padStart(2, '0');
};

const formatColorToInput = (color: string) => {
    return color.replace('#', '').toUpperCase();
};

export const ColorPicker = (props: ColorPickerProps) => {
    const { id, title, isOpened = false, onChange, color, className, scrollContainerId } = props;
    const { isMobile } = useContext(QrContext);
    const [isOpen, setIsOpen] = React.useState(isOpened);
    const [isDragDisabled, setIsDragDisabled] = React.useState(false);
    const [scrollContainer] = React.useState<HTMLElement | null>(document.getElementById(scrollContainerId));

    const coloredBoxRef = React.useRef<HTMLDivElement>(null);

    const [colorPickerTop, setColorPickerTop] = React.useState(0);
    const [colorPickerLeft, setColorPickerLeft] = React.useState(0);
    const [colorPickerBottom, setColorPickerBottom] = React.useState(0);

    const [colorInput, setColorInput] = React.useState(formatColorToInput(color));

    const handleOpen = React.useCallback(() => {
        setIsOpen(!isOpen);

        if (coloredBoxRef.current) {
            setColorPickerLeft(coloredBoxRef.current.getBoundingClientRect().left);
            setColorPickerTop(coloredBoxRef.current.getBoundingClientRect().top);
            setColorPickerBottom(window.innerHeight - coloredBoxRef.current.getBoundingClientRect().bottom);
        }
    }, [isOpen, isMobile]);

    const handleClose = React.useCallback(
        (evt: MouseEvent) => {
            if (evt.target instanceof Element) {
                const clickedElem = evt.target;
                if (clickedElem?.closest(`[id='${id}']`)) {
                    return;
                }

                setIsOpen(false);
            }
        },
        [id, isMobile],
    );

    const handleCloseModal = React.useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleScroll = React.useCallback(() => {
        setIsOpen(false);
    }, []);

    const isValidColor = React.useCallback((hexColor: string) => {
        return [3, 6, 8].indexOf(hexColor.length) !== -1 && hexColor.match(/^[A-F0-9]{3,8}$/i);
    }, []);

    const sanitizeColor = React.useCallback((color: string) => {
        return color.replaceAll(/[^A-F0-9]/gi, '');
    }, []);

    const handleChange = React.useCallback(
        (evt: ChangeEvent<HTMLInputElement>) => {
            const newColor = sanitizeColor(evt.target.value);

            setColorInput(newColor);

            if (!isValidColor(newColor)) {
                return;
            }

            onChange(`#${newColor}`);
        },
        [isValidColor, onChange],
    );

    const handleBlur = React.useCallback(
        (evt) => {
            if (!colorInput) {
                setColorInput(formatColorToInput(color));
            }
        },
        [color, colorInput],
    );

    const handleChangePickerBox = React.useCallback(
        (color: ColorResult) => {
            const newColor = color.rgb.a === 1 ? `${color.hex}` : `${color.hex}${formatAlphaToHex(color.rgb.a)}`;

            onChange(newColor);
            setColorInput(formatColorToInput(newColor));
        },
        [onChange],
    );

    React.useEffect(() => {
        const debouncedHandleClose = (evt: MouseEvent) => {
            setTimeout(() => handleClose(evt));
        };

        if (isOpen && !isMobile) {
            document.addEventListener('click', debouncedHandleClose, true);
            if (scrollContainer) {
                scrollContainer.addEventListener('scroll', handleScroll);
            }
        }

        return () => {
            document.removeEventListener('click', debouncedHandleClose, true);
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleClose, handleScroll, isOpen, scrollContainer]);

    const handleTouchStart = () => {
        setIsDragDisabled(true);
    };

    const handleTouchEnd = () => {
        setIsDragDisabled(false);
    };

    const debouncedHandleOpen = () => {
        setTimeout(() => handleOpen(), 100);
    };

    return (
        <SliderBox className={className}>
            <TextBox onClick={!isMobile ? handleOpen : undefined}>
                <TitleText align="center" typography="headline-xs">
                    {title}
                </TitleText>
            </TextBox>
            <ColoredSliderBox isMobile={isMobile}>
                <ColoredBox color={color} onClick={debouncedHandleOpen} ref={coloredBoxRef} />
                {isOpen ? (
                    !isMobile ? (
                        <QRPortal parentBlockId={popupWrapperId}>
                            <ColorPickerBox
                                id={id}
                                top={colorPickerTop}
                                left={colorPickerLeft}
                                direction={colorPickerBottom > colorPickerHeight ? Direction.Down : Direction.Up}
                                isMobile={isMobile}
                            >
                                <SketchPicker color={color} onChange={handleChangePickerBox} />
                            </ColorPickerBox>
                        </QRPortal>
                    ) : (
                        <InfoDrawer
                            onClose={handleCloseModal}
                            isVisible={isOpen}
                            isDisabledDrag={isDragDisabled}
                            isFullScreen={false}
                        >
                            <MobilePickerWrapper>
                                <ColorPickerBox
                                    id={id}
                                    top={0}
                                    left={0}
                                    direction={Direction.Down}
                                    isMobile={isMobile}
                                    onTouchStart={handleTouchStart}
                                    onTouchEnd={handleTouchEnd}
                                >
                                    <MobileColorPicker
                                        color={color}
                                        onChange={handleChangePickerBox as ColorWrapChangeHandler}
                                    />
                                </ColorPickerBox>
                            </MobilePickerWrapper>
                        </InfoDrawer>
                    )
                ) : null}
                <Input
                    renderControl={(p) => (
                        <input {...p} className="Textinput-Control" onChange={handleChange} value={colorInput} />
                    )}
                    size="m"
                    view="default"
                    value={colorInput}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={8}
                />
            </ColoredSliderBox>
        </SliderBox>
    );
};
