import {
    InputLoader,
    LoaderContainer,
    LoaderLabel,
    DropText,
    Wrapper,
    DescriptionText,
    ErrorText,
    ButtonText,
} from './styles';
import React, { ReactNode, useContext } from 'react';
import { customAlphabet } from 'nanoid';
import { yQrIdAlphabet } from '../../constants/yQRID';
import { QrContext } from '../../App';

export interface FilesDragAndDropProps {
    preview: ReactNode;

    errors: ReactNode | undefined;

    acceptFiles?: string;

    description?: string | ReactNode;

    onChange: (file: File | undefined) => void;
}

export const FilesDragAndDrop = ({ preview, onChange, acceptFiles, errors, description }: FilesDragAndDropProps) => {
    const { isMobile } = useContext(QrContext);

    const nano = React.useCallback(customAlphabet(yQrIdAlphabet, 11), []);
    const id = React.useMemo(nano, [nano]);

    const [isLabelPressed, setIsLabelPressed] = React.useState(false);
    const [isHighlighted, setIsHighlighted] = React.useState(false);
    const loaderContainerRef = React.useRef<HTMLDivElement>(null);

    const handleMouseDown = React.useCallback(() => {
        setIsLabelPressed(true);
    }, []);

    const handleMouseUp = React.useCallback(() => {
        setIsLabelPressed(false);
    }, []);

    const handleDragEnter = React.useCallback((evt) => {
        evt.preventDefault();
        setIsHighlighted(true);
    }, []);

    const handleDragLeave = React.useCallback(
        (evt) => {
            evt.preventDefault();
            evt.stopPropagation();

            const hoveredElem = evt.relatedTarget as Element;
            if (hoveredElem.closest(`[id='${id}']`)) {
                return;
            }

            setIsHighlighted(false);
        },
        [id],
    );

    const handleDragOver = React.useCallback((evt) => {
        evt.preventDefault();
        setIsHighlighted(true);
    }, []);

    const handleChange = React.useCallback(
        (evt) => {
            let file = evt.target.files[0];

            onChange(file);
        },
        [onChange],
    );

    const handleDrop = React.useCallback(
        (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            setIsHighlighted(false);

            let dt = evt.dataTransfer;
            let files = dt.files;

            onChange(files[0]);
        },
        [onChange],
    );

    return (
        <>
            {!preview && (
                <Wrapper>
                    <LoaderContainer
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        isHighlighted={isHighlighted}
                        onDragOver={handleDragOver}
                        id={id}
                        ref={loaderContainerRef}
                        isMobile={isMobile}
                    >
                        <InputLoader type="file" id="input-loader" accept={acceptFiles} onChange={handleChange} />
                        {!isMobile && <DropText typography="body-long-xl">Перетащите файл сюда или</DropText>}
                        <LoaderLabel
                            htmlFor="input-loader"
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            isPressed={isLabelPressed}
                            isMobile={isMobile}
                        >
                            <ButtonText typography="body-long-xl">
                                {!isMobile ? 'загрузите с устройства' : 'Загрузите файл'}
                            </ButtonText>
                        </LoaderLabel>
                    </LoaderContainer>
                    {errors && isMobile ? null : (
                        <DescriptionText typography="body-long-xl">{description}</DescriptionText>
                    )}
                </Wrapper>
            )}
            {preview}
            {Boolean(errors) && (
                <ErrorText typography="body-long-m" $isMobile={isMobile}>
                    {errors}
                </ErrorText>
            )}
        </>
    );
};
