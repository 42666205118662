import {
    CheckBoxWrapper,
    QRIdCheckbox,
    TooltipText,
    QRTooltip,
    MobileModal,
    Wrapper,
    CloseWrapper,
    QuestionWrapper,
} from './styles';
import React, { ReactNode, useContext } from 'react';
import { Question } from '../../images/question/Question';
import { Close } from '../../images/close/Close';
import { QrContext } from '../../App';
import { QRVisitParams, sendParams } from '../../constants/Counter';

interface CheckBoxProps {
    isChecked: boolean;
    onChange: () => void;
    tooltip: string | ReactNode;
}

export const CheckBoxWithTooltip = ({ isChecked, tooltip, onChange }: CheckBoxProps) => {
    const { isMobile } = useContext(QrContext);
    const [isVisible, setIsVisible] = React.useState(false);

    const handleOpen = React.useCallback(() => {
        setIsVisible((isVisible) => !isVisible);
    }, []);

    const handleClose = React.useCallback(() => {
        setIsVisible(false);
    }, []);

    const handleChange = React.useCallback(() => {
        sendParams({
            [QRVisitParams.ChangeYQrId]: !isChecked ? 'on' : 'off',
        });
        onChange();
    }, [isChecked, onChange]);

    const TooltipContent = (
        <>
            <TooltipText typography="body-long-xl">{tooltip}</TooltipText>
        </>
    );

    return (
        <CheckBoxWrapper>
            <QRIdCheckbox
                size="m"
                view="default"
                label="Добавить идентификатор QR"
                checked={isChecked}
                onChange={handleChange}
            />
            {isMobile ? (
                <>
                    <QuestionWrapper onClick={handleOpen}>
                        <Question />
                    </QuestionWrapper>
                    <MobileModal theme="normal" visible={isVisible} onClose={handleClose}>
                        <Wrapper>
                            {TooltipContent}
                            <CloseWrapper onClick={handleClose}>
                                <Close size={11} fillOpacity="1" fill="#A8BDE680" />
                            </CloseWrapper>
                        </Wrapper>
                    </MobileModal>
                </>
            ) : (
                <QRTooltip view="default" size="m" content={TooltipContent} direction="bottom" trigger="click">
                    <div style={{ marginLeft: 8, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Question />
                    </div>
                </QRTooltip>
            )}
        </CheckBoxWrapper>
    );
};
