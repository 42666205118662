import * as React from 'react';

import { InfoWrapper, Link, LogoWrapper, QRHeaderText, QRHeaderWrapper, QRText, Wrapper } from './styles';
import { YandexLogo } from '../../images/yandexLogo/YandexLogo';
import { Question } from '../../images/question/Question';
import { QrContext } from '../../App';
import { QRVisitParams, sendParams } from '../../constants/Counter';
import { useContext } from 'react';

interface HeaderProps {
    onClickInfo: () => void;
}

const headerText = {
    desktop: (
        <>
            Помогите клиентам быстро найти вашу страницу в&nbsp;интернете. Благодаря QR-коду клиентам не&nbsp;придётся
            вводить&nbsp;вручную ссылку на ваш сайт или любой другой онлайн-ресурс. Достаточно отсканировать QR-код.
        </>
    ),
    mobile: <>Помогите клиентам быстро найти вашу страницу в&nbsp;интернете</>,
};

export const Header = ({ onClickInfo }: HeaderProps) => {
    const { isMobile } = useContext(QrContext);

    const handleClickInfo = React.useCallback(() => {
        sendParams({
            [QRVisitParams.ClickOnHelpAboutQR]: 'yes',
        });

        onClickInfo();
    }, [onClickInfo]);

    return (
        <QRHeaderWrapper>
            <Wrapper isMobile={isMobile}>
                <LogoWrapper>
                    <Link href="https://ya.ru">
                        <YandexLogo />
                    </Link>
                    <QRHeaderText typography={isMobile ? 'headline-m' : 'headline-xl'} $isMobile={isMobile}>
                        QR-генератор
                    </QRHeaderText>
                </LogoWrapper>
                <InfoWrapper onClick={handleClickInfo} isMobile={isMobile}>
                    {isMobile ? (
                        <Question />
                    ) : (
                        <QRText typography="body-long-xl" weight="light" $colorText="475A8080">
                            Что такое QR-код?
                        </QRText>
                    )}
                </InfoWrapper>
            </Wrapper>
            <QRText typography="body-long-xl" $colorText="1A2B4D99">
                {isMobile ? headerText.mobile : headerText.desktop}
            </QRText>
        </QRHeaderWrapper>
    );
};
