import React, { FC, ReactNode, useContext } from 'react';

import { ExpandedRow, ExpandedRowProps } from '../ExpandedRow/ExpandedRow';
import { InfoDrawer } from '../InfoDrawer/InfoDrawer';

import { InfoContent } from './styles';
import { QrContext } from '../../App';
import { EventReadingFAQ, EventReadingFAQState, QRVisitParams, sendParams } from '../../constants/Counter';

interface ExpandedRowInfo {
    header: string | ReactNode;
    children: ReactNode;

    event: EventReadingFAQ;
}

interface AboutQRProps {
    isModalVisible: boolean;
    rows: ExpandedRowInfo[];
    onCloseInfo: () => void;
}

const InfoRow: FC<ExpandedRowProps & { event: EventReadingFAQ }> = ({ header, event, children, isFirst = false }) => {
    const sendFAQParams = React.useCallback(
        (isExpanded: boolean) => {
            sendParams({
                [QRVisitParams.ReadingFAQ]: {
                    [event]: isExpanded ? EventReadingFAQState.Expanded : EventReadingFAQState.Collapsed,
                },
            });
        },
        [event],
    );

    const handleExpand = React.useCallback(() => {
        sendFAQParams(true);
    }, [sendFAQParams]);

    const handleCollapse = React.useCallback(() => {
        sendFAQParams(false);
    }, [sendFAQParams]);

    return (
        <ExpandedRow header={header} isFirst={isFirst} onExpand={handleExpand} onCollapse={handleCollapse}>
            {children}
        </ExpandedRow>
    );
};

export const AboutQR = ({ isModalVisible, rows, onCloseInfo }: AboutQRProps) => {
    const { isMobile } = useContext(QrContext);

    return (
        <InfoDrawer
            isVisible={isModalVisible}
            onClose={onCloseInfo}
            header="Ответы на частые вопросы про QR-код"
            isFullScreen
        >
            <InfoContent isMobile={isMobile}>
                {rows.map((item, index) => {
                    return (
                        <InfoRow header={item.header} isFirst={index === 0} key={index} event={item.event}>
                            {item.children}
                        </InfoRow>
                    );
                })}
            </InfoContent>
        </InfoDrawer>
    );
};
