import React from 'react';

export interface CloseProps {
    fill?: string;
    fillOpacity?: string;

    size?: number;
}

export const Close = ({ fill = '#1A2B4D', fillOpacity = 'none', size = 16 }: CloseProps) => {
    return (
        <svg width={size} height={size} viewBox={`0 0 16 16`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.2071 2.20711C15.5976 1.81658 15.5976 1.18342 15.2071 0.792893C14.8166 0.402369 14.1834 0.402369 13.7929 0.792893L8 6.58579L2.20711 0.792893C1.81658 0.402369 1.18342 0.402369 0.792893 0.792893C0.402369 1.18342 0.402369 1.81658 0.792893 2.20711L6.58579 8L0.792893 13.7929C0.402369 14.1834 0.402369 14.8166 0.792893 15.2071C1.18342 15.5976 1.81658 15.5976 2.20711 15.2071L8 9.41421L13.7929 15.2071C14.1834 15.5976 14.8166 15.5976 15.2071 15.2071C15.5976 14.8166 15.5976 14.1834 15.2071 13.7929L9.41421 8L15.2071 2.20711Z"
                fill={fill}
                fillOpacity={fillOpacity}
            />
        </svg>
    );
};
