import styled from 'styled-components';
import { Saturation } from 'react-color/lib/components/common';
import { CirclePicker } from 'react-color';

export const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;

    background: #fff;
`;

export const CustomSaturation = styled(Saturation)`
    border-radius: 14px;
`;

export const SaturationWrapper = styled.div`
    width: 100%;
    padding-bottom: 75%;
    position: relative;
    overflow: hidden;

    border-radius: 14px 14px 0 0;
`;

export const SaturationCursor = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 1.5px, rgba(0, 0, 0, 0.3) 0px 0px 1px 1px inset,
        rgba(0, 0, 0, 0.4) 0px 0px 1px 2px;
    transform: translate(-2px, -2px);
`;

export const HueWrapper = styled.div`
    position: relative;
    height: 16px;
    overflow: hidden;
`;

export const SlidersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    padding-top: 8px;
    padding-bottom: 8px;
`;

export const SlidersCursor = styled.div`
    width: 4px;
    border-radius: 1px;
    height: 14px;
    margin-top: 1px;
    box-shadow: rgba(0, 0, 0, 0.6) 0 0 2px;
    background: #fff;
    transform: translateX(-2px);
`;

export const AlphaWrapper = styled.div`
    position: relative;
    height: 16px;
    overflow: hidden;

    margin-top: 12px;
`;

export const ControlsWrapper = styled.div`
    display: flex;
`;

export const CheckboardWrapper = styled.div`
    position: relative;
    width: 44px;
    height: 44px;
    margin-top: 8px;
    margin-left: 8px;
    border-radius: 3px;
`;

export const ActiveColorPreview = styled.div<{ rgb?: { r: number; g: number; b: number; a?: number } }>`
    position: absolute;
    inset: 0;
    border-radius: 2px;
    background: ${({ rgb }) => rgb && `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a || 1})`};
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 0 4px rgba(0, 0, 0, 0.25);
`;

export const SuggestedColor = styled(CirclePicker)`
    padding-left: 4px;
`;
