import styled from 'styled-components';
import { Textarea } from '@yandex-lego/components/Textarea/desktop/bundle';

export const Wrapper = styled.div`
    position: relative;
`;

export const UrlInputTextarea = styled(Textarea)<{ height?: number; $isScrollHidden?: boolean }>`
    & .Textarea-Wrap {
        align-items: center;
        box-sizing: border-box;
    }

    & .Textarea-Box {
        border-radius: 12px;
        border-width: 1px;
    }
    & .Textarea-Control {
        resize: none;
        padding: 12px 100px 12px 16px;
        height: ${({ height }) => height && `${height}px`};
        transition: height 0.2s;
        overflow-y: ${({ $isScrollHidden }) => $isScrollHidden && 'hidden'};
        color: #2f3747;
        font-weight: 400;
        font-size: 20px;
    }

    & .Textarea-Hint {
        margin-top: 8px;
        font-size: 14px;
    }
`;

export const HiddenTextarea = styled(UrlInputTextarea)`
    position: absolute;
    top: -9999px;
    left: -9999px;
    visibility: hidden;
    height: 52px;
    width: 100%;
`;

export const Controls = styled.div`
    position: absolute;
    display: flex;
    top: 16px;
    right: 18px;
    z-index: 10;
`;

export interface CounterProps {
    hasError: boolean;
}
export const Counter = styled.div<CounterProps>`
    font-family: YS Text;
    font-size: 20px;
    margin-right: 20px;
    margin-top: -2px;
    color: ${({ hasError }) => (hasError ? '#DD0000' : '#1a2b4d99;')};
`;
