import styled from 'styled-components';
import { Text } from '@yandex-lego/components/Text/bundle';

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
`;

export const HeaderText = styled(Text)`
    color: #2f3747;
`;

export const Container = styled.div<{ isFirst: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: ${({ isFirst }) => !isFirst && `30px`};
`;

export const TextWrapper = styled.div<{ $isExpanded: boolean }>`
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    max-height: ${({ $isExpanded }) => ($isExpanded ? `350px` : `0px`)};
`;

export const ContentText = styled(Text)`
    color: #1a2b4d99;
`;

export const ArrowWrapper = styled.div<{ isExpanded: boolean }>`
    display: flex;
    align-items: center;

    transform: ${({ isExpanded }) => isExpanded && `rotate(180deg)`};

    transition: transform 0.3s ease-in-out;
`;
