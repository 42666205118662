import React, { useContext } from 'react';

import { Container, GroupButtons, HiddenCanvas, Link, SavingTitle, SingleButton, TextWrapper } from './styles';
import { MimeType, svgToCanvasWithFormat } from '../../helpers/svgToCanvasWithFormat/svgToCanvasWithFormat';
import { QrContext } from '../../App';
import { EventDownloadByButton, isCustomQR, QRGoals, reachGoal } from '../../constants/Counter';

export interface SaveGroupButtonsProps {
    url: string;
    qrSVGBase64: string;
}

enum Format {
    Svg = 'svg',
    Png = 'png',
    Jpeg = 'jpeg',
}

export const SaveGroupButtons = ({ url, qrSVGBase64 }: SaveGroupButtonsProps) => {
    const { isMobile, counter } = useContext(QrContext);

    const hiddenCanvasPngRef = React.useRef<HTMLCanvasElement>(null);
    const hiddenCanvasJpegRef = React.useRef<HTMLCanvasElement>(null);

    const downloadSVGRef = React.useRef<HTMLAnchorElement>(null);
    const downloadPNGRef = React.useRef<HTMLAnchorElement>(null);
    const downloadJPEGRef = React.useRef<HTMLAnchorElement>(null);

    const createDownloadFormat = React.useCallback(
        async (
            saveButtonRef: React.RefObject<HTMLAnchorElement>,
            hiddenCanvasRef: React.RefObject<HTMLCanvasElement>,
            format = MimeType.Png,
        ) => {
            if (!(saveButtonRef && saveButtonRef.current)) {
                return;
            }

            if (!(hiddenCanvasRef && hiddenCanvasRef.current)) {
                return;
            }

            await svgToCanvasWithFormat({
                canvas: hiddenCanvasRef.current,
                qrSVGBase64: qrSVGBase64,
                format: format,
            });

            saveButtonRef.current.href = hiddenCanvasRef?.current?.toDataURL(format, 1.0) || '#';
        },
        [qrSVGBase64],
    );

    const pushCounterSettings = React.useCallback(
        (clickedButton: EventDownloadByButton) => {
            const goal = isCustomQR(counter) ? QRGoals.DownloadCustomQR : QRGoals.DownloadSimpleQR;
            const counterParams =
                goal === QRGoals.DownloadCustomQR ? { [clickedButton]: counter } : { [clickedButton]: 'yes' };

            reachGoal(goal, {
                [goal]: counterParams,
            });
        },
        [counter],
    );

    const handleClickSVG = React.useCallback(() => {
        pushCounterSettings(EventDownloadByButton.ClickOnSVGSaveButton);
    }, [pushCounterSettings]);

    const handleClickPNG = React.useCallback(() => {
        pushCounterSettings(EventDownloadByButton.ClickOnPNGSaveButton);
    }, [pushCounterSettings]);

    const handleClickJPEG = React.useCallback(() => {
        pushCounterSettings(EventDownloadByButton.ClickOnJPEGSaveButton);
    }, [pushCounterSettings]);

    React.useEffect(() => {
        if (downloadSVGRef && downloadSVGRef.current) {
            downloadSVGRef.current.href = qrSVGBase64;
        }

        createDownloadFormat(downloadPNGRef, hiddenCanvasPngRef);
        createDownloadFormat(downloadJPEGRef, hiddenCanvasJpegRef, MimeType.Jpeg);
    }, [createDownloadFormat, qrSVGBase64]);

    return (
        <Container isVisible={Boolean(url)} isMobile={isMobile}>
            <TextWrapper>
                <SavingTitle typography="body-long-xl">Сохранить</SavingTitle>
            </TextWrapper>
            <GroupButtons isMobile={isMobile}>
                <Link
                    download={`YQR.${Format.Svg}`}
                    ref={downloadSVGRef}
                    isFirst
                    isDisabled={!Boolean(url)}
                    isMobile={isMobile}
                >
                    <SingleButton view="default" size="m" width="max" $isMobile={isMobile} onClick={handleClickSVG}>
                        {Format.Svg.toUpperCase()}
                    </SingleButton>
                </Link>
                <Link
                    download={`YQR.${Format.Png}`}
                    ref={downloadPNGRef}
                    isDisabled={!Boolean(url)}
                    isMobile={isMobile}
                >
                    <SingleButton view="default" size="m" width="max" $isMobile={isMobile} onClick={handleClickPNG}>
                        {Format.Png.toUpperCase()}
                    </SingleButton>
                </Link>
                <Link
                    download={`YQR.${Format.Jpeg}`}
                    ref={downloadJPEGRef}
                    isDisabled={!Boolean(url)}
                    isMobile={isMobile}
                >
                    <SingleButton view="default" size="m" width="max" $isMobile={isMobile} onClick={handleClickJPEG}>
                        {Format.Jpeg.toUpperCase()}
                    </SingleButton>
                </Link>
            </GroupButtons>
            <HiddenCanvas ref={hiddenCanvasPngRef} />
            <HiddenCanvas ref={hiddenCanvasJpegRef} />
        </Container>
    );
};
