import React from 'react';

export const YandexLogo = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="#FC3F1D" />
            <path
                d="M27.7682 39H33V9H25.3841C17.7241 9 13.7064 12.9207 13.7064 18.7137C13.7064 23.7137 16.3113 26.533 20.9249 29.5947L23.7285 31.4449L20.1302 28.4273L13 39H18.6733L26.2892 27.6344L23.6402 25.8722C20.4393 23.7136 18.872 22.0396 18.872 18.4053C18.872 15.2115 21.1236 13.0529 25.4062 13.0529H27.7461V39H27.7682Z"
                fill="white"
            />
        </svg>
    );
};
