import styled from 'styled-components';
import { Text } from '@yandex-lego/components/Text/bundle';

export const Container = styled.div<{ isExpanded: boolean; isMobile: boolean }>`
    margin-top: ${({ isMobile }) => (isMobile ? `24px` : `28px`)};
    display: ${({ isExpanded }) => isExpanded && `flex`};
    flex-direction: ${({ isExpanded }) => isExpanded && `row`};
    justify-content: ${({ isExpanded }) => isExpanded && `space-between`};
`;

export const SettingsWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
`;

export const ContentWrapper = styled.div<{ isExpanded: boolean }>`
    display: flex;
    flex-direction: column;

    max-height: ${({ isExpanded }) => (isExpanded ? `700px` : `0px`)};
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: ${({ isExpanded }) => (isExpanded ? `1` : `0`)};
    pointer-events: ${({ isExpanded }) => !isExpanded && `none`};
`;

export const TextContainer = styled.div`
    cursor: pointer;
    display: inline-flex;
`;

export const ExpandedTitle = styled(Text)<{ $isExpanded: boolean }>`
    color: #2f3747;
    height: 25px;
    border-bottom: ${({ $isExpanded }) => !$isExpanded && `1px dashed #2F3747`};
    box-sizing: border-box;
`;

export const CloseText = styled(Text)`
    color: #475a8080;
`;

export const TabsContentWrapper = styled.div<{ isMobile: boolean; isExpanded: boolean }>`
    height: ${({ isMobile }) => isMobile && `200px`};
    margin-bottom: ${({ isMobile }) => isMobile && `8px`};
`;
