import React from 'react';

export const Question = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM5.178 6.096C5.178 4.44 6.546 3.432 8.274 3.432C9.978 3.432 11.25 4.44 11.25 5.928V5.976C11.25 7.392 10.338 7.968 9.486 8.34L9.27 8.436C8.982 8.568 8.886 8.724 8.886 9.072C8.886 9.24431 8.74631 9.384 8.574 9.384H7.734C7.49541 9.384 7.302 9.19059 7.302 8.952C7.302 8.136 7.59 7.62 8.346 7.284L8.562 7.188C9.282 6.864 9.666 6.6 9.666 6C9.666 5.352 9.114 4.92 8.274 4.92C7.41 4.92 6.762 5.352 6.762 6.168C6.762 6.32706 6.63306 6.456 6.474 6.456H5.538C5.33918 6.456 5.178 6.29482 5.178 6.096ZM8.094 9.888C7.446 9.888 6.954 10.356 6.954 11.028C6.954 11.7 7.446 12.168 8.094 12.168C8.742 12.168 9.234 11.7 9.234 11.028C9.234 10.356 8.742 9.888 8.094 9.888Z"
                fill="#475A80"
                fillOpacity="0.5"
            />
        </svg>
    );
};
