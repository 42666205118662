import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle<{
    isMobile?: boolean;
    height?: number;
}>`
  body {
    background: rgba(239, 242, 247, 1);
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
  }

  html {
    height: ${({ isMobile, height }) => (isMobile ? `${height}px` : `100%`)};
  }

  #root {
    height: 100%;
    max-width: 100%;
  }

`;

export const BodyWrapper = styled.div<{ isMobile?: boolean }>`
    height: 100%;
    min-height: ${({ isMobile }) => (isMobile ? `100%` : `580px`)};
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;

    -webkit-overflow-scrolling: ${({ isMobile }) => isMobile && `touch`};
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
`;

export const Content = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;

    width: ${({ isMobile }) => (isMobile ? `100%` : `1200px`)};
    min-width: 256px;

    margin-left: auto;
    margin-right: auto;

    flex-shrink: 0;
    flex-grow: 1;
`;
