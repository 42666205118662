import React from 'react';
import {
    Wrapper,
    CustomSaturation,
    SaturationWrapper,
    SaturationCursor,
    HueWrapper,
    SlidersWrapper,
    SlidersCursor,
    AlphaWrapper,
    ControlsWrapper,
    CheckboardWrapper,
    ActiveColorPreview,
} from './styles';
import { Alpha, Checkboard, Hue } from 'react-color/lib/components/common';
import { ColorResult, CustomPicker, HSLColor, InjectedColorProps, RGBColor } from 'react-color';
import { ColorWrapChangeHandler } from 'react-color/lib/components/common/ColorWrap';

export interface MobileColorPickerProps extends InjectedColorProps {
    hex?: string | undefined;
    hsl?: HSLColor | undefined;
    rgb?: RGBColor | undefined;
    onChange: ColorWrapChangeHandler;
}

const MobileColorPicker = (props: MobileColorPickerProps) => {
    const { onChange } = props;

    const handleChange = React.useCallback((color: ColorResult, evt) => {
        onChange(color);
    }, []);

    return (
        <Wrapper>
            <SaturationWrapper>
                <CustomSaturation {...props} onChange={handleChange} pointer={SaturationCursor} />
            </SaturationWrapper>
            <ControlsWrapper>
                <SlidersWrapper>
                    <HueWrapper>
                        <Hue {...props} direction={'horizontal'} pointer={SlidersCursor} />
                    </HueWrapper>
                    <AlphaWrapper>
                        <Alpha {...props} pointer={SlidersCursor} />
                    </AlphaWrapper>
                </SlidersWrapper>
                <CheckboardWrapper>
                    <Checkboard />
                    <ActiveColorPreview rgb={props.rgb} />
                </CheckboardWrapper>
            </ControlsWrapper>
        </Wrapper>
    );
};

export default CustomPicker(MobileColorPicker);
