import React from 'react';
import { FooterText, TextWrapper, Wrapper } from './styles';

export interface FooterProps {
    isMobile: boolean;
}


export const Footer = ({isMobile}: FooterProps) => {
    return (
        <Wrapper isMobile={isMobile}>
            <TextWrapper isMobile={isMobile}>
                <FooterText typography="body-long-xl">© {new Date().getFullYear()} ООО «Яндекс»</FooterText>
            </TextWrapper>
        </Wrapper>
    );
};
