import {
    LogoImage,
    PreviewLogo,
    ClearContainer,
    Wrapper,
    DescriptionContainer,
    ImageName,
    ImageResolution,
    HiddenInput,
    LabelText,
    ChangerImageWrapper,
} from './styles';
import React, { useContext } from 'react';
import { Remove } from '../../images/remove/Remove';
import { QrContext } from '../../App';

import { reduceRow } from '../../helpers/reduceRow/reduceRow';

export interface Logo {
    logoBase64: string;
    name: string;
}

interface LogoPreviewProps {
    logo: Logo;
    acceptFiles?: string;
    onChangeLogo: (file: File | undefined) => void;
}

export const LogoPreview = ({ logo, acceptFiles, onChangeLogo }: LogoPreviewProps) => {
    const imageRef = React.useRef<HTMLImageElement>(null);
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const { isMobile } = useContext(QrContext);

    const setResolution = React.useCallback((logo: Logo) => {
        const newImg = new Image();

        newImg.onload = function () {
            setHeight(newImg.height);
            setWidth(newImg.width);
        };

        newImg.src = `${logo.logoBase64}`;
    }, []);

    React.useEffect(() => {
        if (imageRef && imageRef.current) {
            if (logo.logoBase64) {
                imageRef.current.src = `${logo.logoBase64}`;
            }
        }

        setResolution(logo);
    }, [logo, setResolution]);

    const handleChange = React.useCallback(
        (evt) => {
            let file = evt.target.files[0];

            onChangeLogo(file);
        },
        [onChangeLogo],
    );

    const handleClickRemove = React.useCallback(() => {
        onChangeLogo(undefined);
    }, [onChangeLogo]);

    return (
        <Wrapper isMobile={isMobile}>
            <PreviewLogo>
                <LogoImage ref={imageRef} />
                <ClearContainer onClick={handleClickRemove}>
                    <Remove />
                </ClearContainer>
            </PreviewLogo>
            <DescriptionContainer>
                <ImageName typography="body-long-xl">{reduceRow(logo?.name, 20, 6, -7)}</ImageName>
                <ImageResolution typography="body-long-m">
                    {width} × {height}
                </ImageResolution>
                <HiddenInput type="file" id="input-changeLoader" accept={acceptFiles} onChange={handleChange} />
                <ChangerImageWrapper htmlFor="input-changeLoader">
                    <LabelText typography="body-long-m">Заменить</LabelText>
                </ChangerImageWrapper>
            </DescriptionContainer>
        </Wrapper>
    );
};
