import { minResolutionSize } from '../../constants/QR';
import { acceptedFiles, AcceptedFileType } from '../../components/ImagesDragAndDrop/ImagesDragAndDrop';

export enum LogoErrors {
    SizeError,
    FormatError,
    ResolutionError,
    ReadingError,
}

interface FileForValidation {
    size: number;
    type: string;
    width?: number;
    height?: number;
    isBroken?: boolean;
}

export const getImageHeightAndWidth = (file: File): Promise<{ width?: number; height?: number }> =>
    new Promise((resolve, reject) => {
        if (!file.type.match(/image/g)) {
            reject({});
        }

        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = () => {
            if (typeof reader.result === 'string') {
                const newImg = new Image();
                let width = 0;
                let height = 0;

                newImg.onload = function () {
                    height = newImg.height;
                    width = newImg.width;

                    resolve({ width, height });
                };

                newImg.onerror = () => {
                    reject({ isBroken: true });
                };

                newImg.src = `data:${file.type};base64,${btoa(reader.result)}`;
            }
        };
    });

export const getLogoValidationErrors = (file: FileForValidation) => {
    const errors = [];

    if (file.size > 4194304) {
        errors.push(LogoErrors.SizeError);
    }

    if (acceptedFiles.indexOf(file.type as AcceptedFileType) === -1) {
        errors.push(LogoErrors.FormatError);
    }

    if (file.width && file.height && (file.width < minResolutionSize || file.height < minResolutionSize)) {
        errors.push(LogoErrors.ResolutionError);
    }

    return errors;
};
