import styled from 'styled-components';
import { Text } from '@yandex-lego/components/Text/bundle';

export const Wrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    justify-content: ${({ isMobile }) => !isMobile && `space-between`};
    width: 100%;

    margin-bottom: 14px;
`;

export const QRText = styled(Text)<{ $colorText: string }>`
    color: ${({ $colorText }) => `#${$colorText}`};
`;

export const QRHeaderText = styled(Text)<{ $isMobile: boolean }>`
    color: #2f3747;
    margin-left: 12px;
    font-weight: 500;

    line-height: ${({ $isMobile }) => $isMobile && `32px`};
`;

export const QRHeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-bottom: 15px;
`;

export const InfoWrapper = styled.div<{ isMobile: boolean }>`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-top: ${({ isMobile }) => (!isMobile ? `8px` : `4px`)};
    margin-left: ${({ isMobile }) => isMobile && `8px`};
`;

export const LogoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Link = styled.a`
    text-decoration: none;
    color: inherit;
    cursor: pointer;
`;
