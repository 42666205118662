import styled from 'styled-components';
import { Checkbox } from '@yandex-lego/components/Checkbox/desktop/bundle';
import { Text } from '@yandex-lego/components/Text/bundle';
import { TooltipStateful } from '@yandex-lego/components/Tooltip/desktop/bundle';
import { Modal } from '@yandex-lego/components/Modal/desktop/bundle';

export const QRIdCheckbox = styled(Checkbox)`
    display: flex;
    align-items: center;

    & .Checkbox-Box {
        width: 20px;
        height: 20px;
        border-radius: 5px;
    }

    & .Checkbox-Label {
        font-size: 16px;
        line-height: 24px;

        padding-left: 8px;
        color: #2f3747;
    }

    &.Checkbox_checked .Checkbox-Box[class]::before {
        background-color: #ffdd57;
    }

    &.Checkbox_hovered.Checkbox_checked .Checkbox-Box[class]::before {
        background-color: #fad74f;
    }

    &.Checkbox_checked .Checkbox-Tick {
        color: #2f3747;
    }

    &.Checkbox_size_m .Checkbox-Box {
        width: 20px;
        height: 20px;
        border-radius: 5px;
    }
`;

export const CheckBoxWrapper = styled.div`
    margin-top: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const TooltipText = styled(Text)<{ $isMobile?: boolean }>`
    color: #2f3747;

    width: ${({ $isMobile }) => $isMobile && `230px`};
`;

export const QRTooltip = styled(TooltipStateful)`
    margin-top: 10px;

    padding: 16px 20px;
    box-shadow: 0 10px 20px rgba(165, 177, 202, 0.3), 0 3px 6px rgba(165, 177, 202, 0.3);
    border-radius: 14px;

    width: 461px;

    &.Tooltip_view_default .Tooltip-Backdrop::before {
        background: #ffffff;

        border-radius: 14px;
    }

    & .Tooltip-Content {
        padding: 0;
    }
`;

export const MobileModal = styled(Modal)`
    & .Modal-Content {
        margin-left: 20px;
        margin-right: 20px;

        padding: 16px 16.5px 16px 20px;

        border-radius: 14px;
    }

    & .Modal-Overlay {
        background-color: #2f3747;
        opacity: 0.8;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const CloseWrapper = styled.div`
    display: inline-flex;
    height: fit-content;

    margin-top: 6px;
    margin-left: 10px;
`;

export const QuestionWrapper = styled.div`
    margin-left: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;
