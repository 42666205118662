import styled from 'styled-components';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import { Text } from '@yandex-lego/components/Text/bundle';

export const Container = styled.footer<{ isVisible: boolean; isMobile: boolean }>`
    background-color: #ffffff;
    padding: ${({ isMobile }) => (isMobile ? `12px 18px 12px 16px;` : `28px 32px`)};

    box-shadow: 0 10px 20px rgba(165, 177, 202, 0.3), 0 3px 6px rgba(165, 177, 202, 0.3);
    border-radius: 24px;

    opacity: ${({ isVisible }) => (isVisible ? `1` : `0`)};
    transition: opacity 0.5s ease-in-out;

    margin-top: ${({ isMobile }) => (isMobile ? `58px` : `16px`)};

    margin-bottom: ${({ isMobile }) => (isMobile ? `0` : `24px`)};

    position: -webkit-sticky;
    position: sticky;
    z-index: 3;

    bottom: 8px;

    width: 100%;
    box-sizing: border-box;
`;

export const GroupButtons = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: ${({ isMobile }) => (isMobile ? `10px` : `16px`)};
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const SingleButton = styled(Button)<{ isFirst?: boolean; $isMobile: boolean }>`
    height: 44px;
    border-radius: 14px;
    padding: ${({ $isMobile }) => !$isMobile && `10px 20px`};

    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        background-color: #b0bdd633;
        border-radius: 14px;
    }

    &:not([aria-disabled='true']):hover:before {
        background-color: #a5b1ca4d;
    }
`;

export const Link = styled.a<{ isFirst?: boolean; isDisabled: boolean; isMobile: boolean }>`
    margin-left: ${({ isFirst, isMobile }) => !isFirst && (isMobile ? `8px` : `16px`)};
    width: 100%;
    text-decoration: none;
    &:hover {
        color: inherit;
    }

    pointer-events: ${({ isDisabled }) => isDisabled && `none`};
`;

export const HiddenCanvas = styled.canvas`
    display: none;
`;

export const SavingTitle = styled(Text)`
    color: #2f3747;
`;
