import React, { ReactNode } from 'react';
import { FilesDragAndDrop } from '../../components/FilesDragAndDrop/FilesDragAndDrop';
import { LogoErrors } from '../../validators/validationLogo/validationLogo';
import { Logo } from '../../components/LogoPreview/LogoPreview';
import { LogoPreview } from '../LogoPreview/LogoPreview';
import { reduceRow } from '../../helpers/reduceRow/reduceRow';

interface ImagesDragAndDropProps {
    logo: Logo;
    currentLoadedFileName: string;

    errors: Array<LogoErrors>;

    onChange: (file: File | undefined) => void;
}

const mapErrorToText: Record<LogoErrors, ReactNode> = {
    [LogoErrors.SizeError]: 'Размер не должен превышать 4 Мб',
    [LogoErrors.FormatError]: 'Неподдерживаемый формат файла. Загрузите JPG, PNG, GIF или SVG',
    [LogoErrors.ResolutionError]: 'Разрешение должно быть не меньше 150 × 150 px',
    [LogoErrors.ReadingError]: 'Не удалось прочитать файл',
};

export enum AcceptedMimeType {
    Jpeg = 'image/jpeg',
    Png = 'image/png',
    Svg = 'image/svg+xml',
    Gif = 'image/gif',
}

export const acceptedFiles = Object.values(AcceptedMimeType);

export const ImagesDragAndDrop = ({ logo, errors, onChange, currentLoadedFileName }: ImagesDragAndDropProps) => {
    const errorContent = (
        <>
            {errors.indexOf(LogoErrors.ReadingError) !== -1 ? (
                <>
                    {mapErrorToText[LogoErrors.ReadingError]} {reduceRow(currentLoadedFileName)} <br />
                </>
            ) : (
                <>
                    Не удалось загрузить изображение {reduceRow(currentLoadedFileName)} <br />
                    {errors.map((item, index) => {
                        return (
                            <>
                                {mapErrorToText[item]} <br />
                            </>
                        );
                    })}
                </>
            )}
        </>
    );

    return (
        <FilesDragAndDrop
            preview={
                Boolean(logo.logoBase64) ? (
                    <LogoPreview logo={logo} onChangeLogo={onChange} acceptFiles={acceptedFiles.join(',')} />
                ) : null
            }
            onChange={onChange}
            errors={errors.length > 0 ? errorContent : undefined}
            acceptFiles={acceptedFiles.join(',')}
            description="Разрешение от 150 × 150 пикс, размер не более 4 Мб, в формате JPG, PNG, GIF или SVG"
        />
    );
};
