import * as React from 'react';
import { Controls, Counter, HiddenTextarea, UrlInputTextarea, Wrapper } from './styles';
import { useCallback, useContext, useRef, useState } from 'react';
import { QrContext } from '../../App';
import debounce from 'lodash.debounce';
import { Close } from '../../images/close/Close';
import { Button } from '@yandex-lego/components/Button';

interface QrCodeGenerateConfirmInputProps {
    onChangeUrl: (url: string) => void;
}

const delay = 500;
const maxLength = 100;
const initialHeight = 52;
const maxHeight = 144;
const textAreaBorder = 2;
const closeColor = '#475a8080';

export const QRUrlInput = ({ onChangeUrl }: QrCodeGenerateConfirmInputProps) => {
    const { isMobile } = useContext(QrContext);
    const [currentValue, setCurrentValue] = useState('');
    const [height, setHeight] = useState(initialHeight);
    const [isScrollHidden, setIsScrollHidden] = useState(true);

    const controlRef = useRef<HTMLTextAreaElement | null>(null);
    const hiddenTextAreaRef = useRef<HTMLTextAreaElement | null>(null);

    const debouncedChangeUrl = React.useMemo(() => debounce(onChangeUrl, delay), [onChangeUrl, delay]);

    const handleChangeHeight = React.useCallback(
        (content: string) => {
            if (!hiddenTextAreaRef.current) {
                return;
            }

            hiddenTextAreaRef.current.value = content;

            const textAreaHeight = Math.min(
                Math.max(hiddenTextAreaRef.current.scrollHeight + textAreaBorder * 2, initialHeight),
                maxHeight,
            );
            setHeight(textAreaHeight);
        },
        [setHeight, hiddenTextAreaRef],
    );

    const handleInputChange = useCallback(
        (evt) => {
            setCurrentValue(evt.target.value);
        },
        [setCurrentValue],
    );

    const handleClear = useCallback(() => {
        setCurrentValue('');
    }, [setCurrentValue]);

    React.useEffect(() => {
        handleChangeHeight(currentValue);
        debouncedChangeUrl(currentValue.length > maxLength ? '' : currentValue);
    }, [currentValue]);

    React.useEffect(() => {
        setIsScrollHidden(height !== maxHeight);
    }, [height, setIsScrollHidden]);

    const symbolsLeft = maxLength - currentValue.length;
    const hasError = symbolsLeft < 0;
    return (
        <Wrapper>
            <UrlInputTextarea
                controlRef={controlRef}
                theme="normal"
                size="m"
                state={hasError ? 'error' : undefined}
                value={currentValue}
                onChange={handleInputChange}
                hint={hasError ? 'Превышено допустимое количество символов' : undefined}
                placeholder={
                    isMobile ? 'Введите адрес страницы' : 'Введите ссылку на страницу, чтобы сгенерировать QR код'
                }
                height={height}
                $isScrollHidden={isScrollHidden}
            />
            <HiddenTextarea controlRef={hiddenTextAreaRef} />
            {currentValue.length > 0 && (
                <Controls>
                    <Counter hasError={hasError}>{symbolsLeft}</Counter>
                    <Button icon={() => <Close size={16} fill={closeColor} />} onClick={handleClear} />
                </Controls>
            )}
        </Wrapper>
    );
};
