import React, { Dispatch, SetStateAction } from 'react';
import { useIsMobile } from './hooks/useIsMobile/useIsMobile';
import { mobileWidth } from './constants/QR';
import { CounterColorProperty, CounterSettings } from './constants/Counter';
import { QRPage } from './pages/QRPage/QRPage';

interface QRContextProps {
    isMobile: boolean;
    counter: CounterSettings;
    setCounter: Dispatch<SetStateAction<CounterSettings>>;
}
export const QrContext = React.createContext<QRContextProps>({
    isMobile: false,
    counter: {
        changeQRLogo: false,
        [CounterColorProperty.ChangeBackgroundColor]: false,
        [CounterColorProperty.ChangeForegroundColor]: false,
    },
    setCounter: () => {},
});

function App() {
    const [isMobile] = useIsMobile(mobileWidth);
    const [counter, setCounter] = React.useState<CounterSettings>({
        changeQRLogo: false,
        [CounterColorProperty.ChangeBackgroundColor]: false,
        [CounterColorProperty.ChangeForegroundColor]: false,
    });

    return (
        <QrContext.Provider value={{ isMobile, counter, setCounter }}>
            <QRPage />
        </QrContext.Provider>
    );
}

export default App;
