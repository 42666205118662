import { Content, Link, Paragraph, SettingsBlock, Wrapper } from './styles';
import { Preview } from '../../components/Preview/Preview';
import { Header } from '../../components/Header/Header';
import { QRUrlInput } from '../../components/QRUrlInput/QRUrlInput';
import React, { useContext } from 'react';
import { ExpandedSettings } from '../../components/ExpandedSettings/ExpandedSettings';
import { SaveGroupButtons } from '../../components/SaveGroupButtons/SaveGroupButtons';
import { AboutQR } from '../../components/AboutQR/AboutQR';
import { CheckBoxWithTooltip } from '../../components/CheckBoxWithTooltip/CheckBoxWithTooltip';
import {
    getImageHeightAndWidth,
    getLogoValidationErrors,
    LogoErrors,
} from '../../validators/validationLogo/validationLogo';
import { Logo } from '../../components/LogoPreview/LogoPreview';
import { bodyWrapperId } from '../../constants/QRPageConstans';
import { QrContext } from '../../App';
import { CounterColorProperty, EventReadingFAQ, QRVisitParams, sendParams } from '../../constants/Counter';
import { Layout } from '../../components/Layout/Layout';

const defaultQRCodeColor = '#000000';
const defaultQRCodeBackgroundColor = '#ffffff';

const PopupInfoRows = [
    {
        header: 'Что такое QR-код?',
        children: (
            <>
                QR-код это самый простой способ поделиться любой ссылкой в&nbsp;офлайне.
                <Paragraph>
                    Внутри QR-кода можно зашифровать любую информацию. Это может быть ссылка на&nbsp;сайт вашей
                    компании, на&nbsp;мобильное приложение, промокод или страницу с&nbsp;рекламной акцией. Используйте
                    QR-коды чтобы стать ближе к вашей целевой аудитории.
                </Paragraph>
            </>
        ),
        event: EventReadingFAQ.WhatIsQR,
    },
    {
        header: 'Как отсканировать QR-код?',
        children: (
            <>
                Наведите камеру смартфона на&nbsp;изображение QR-кода и&nbsp;перейдите по&nbsp;появившейся ссылке. Умная
                камера Яндекса автоматически распознаёт QR-коды, попавшие в&nbsp;поле видимости.
            </>
        ),
        event: EventReadingFAQ.HowScanQR,
    },
    {
        header: 'Как создать QR-код?',
        children: (
            <>
                Введите ссылку в&nbsp;онлайн-генератор, и&nbsp;скачайте готовый QR-код в&nbsp;удобном для вас формате.
                <Paragraph>
                    Для тонкой настройки цвета и&nbsp;дизайнов, используйте кнопку "расширенные настройки".
                </Paragraph>
                <Paragraph>
                    Вы&nbsp;также можете добавить самостоятельно добавить в&nbsp;ссылку UTM-метку, или использовать
                    готовый идентификатор QR-кода чтобы узнать, как часто люди сканируют ваш QR-код.
                </Paragraph>
            </>
        ),
        event: EventReadingFAQ.HowCreateQR,
    },
    {
        header: 'Где использовать QR-код?',
        children: (
            <>
                QR-код это мощный инструмент для рекламы вашего бизнеса.
                <Paragraph>
                    Разместите QR-код в&nbsp;любом удобном месте: в&nbsp;зоне ожидания для клиентов, на&nbsp;кассе,
                    рекламных баннерах и вывесках, слайде в&nbsp;презентации, товарах или на&nbsp;сувенирной продукции.{' '}
                </Paragraph>
                <Paragraph>
                    Используйте QR-коды для продвижения ваших товаров и&nbsp;услуг, акций и&nbsp;спецпредложений.
                </Paragraph>
            </>
        ),
        event: EventReadingFAQ.WhereUseQR,
    },
];

const YQrIdTooltip = () => {
    return (
        <>
            Генерируя QR-код из&nbsp;ссылки, вы&nbsp;можете самостоятельно добавить в&nbsp;ссылку UTM-метку или
            автоматически сгенерировать идентификатор QR-кода. Так вы&nbsp;сможете узнать, как много людей сканировали
            QR-код для перехода на ваш сайт, и использовать эти данные для аналитики при помощи&nbsp;
            <Link target="_blank" href="https://metrika.yandex.ru" rel="noreferrer">
                Яндекс.Метрики
            </Link>
        </>
    );
};

export const QRPage = () => {
    const { isMobile, setCounter } = useContext(QrContext);

    const [qrSVG, setQrSVG] = React.useState('');
    const [url, setUrl] = React.useState('');
    const [foregroundColor, setForegroundColor] = React.useState(defaultQRCodeColor);
    const [backgroundColor, setBackgroundColor] = React.useState(defaultQRCodeBackgroundColor);
    const [logo, setLogo] = React.useState<Logo>({
        logoBase64: '',
        name: '',
    });
    const [logoError, setLogoError] = React.useState<Array<LogoErrors>>([]);
    const [currentLoadedFileName, setCurrentLoadedFileName] = React.useState('');
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [isYQREnabled, setIsYQREnabled] = React.useState(true);

    const handleChangeChecked = React.useCallback(() => {
        setIsYQREnabled(!isYQREnabled);
    }, [isYQREnabled]);

    const handleCloseInfo = React.useCallback(() => {
        setIsModalVisible(false);
    }, []);

    const handleClickInfo = React.useCallback(() => {
        setIsModalVisible(true);
    }, []);

    const handleChangeQRUrlInput = React.useCallback((url) => {
        setUrl(url);
    }, []);

    const setChangeColorInCounterSettings = React.useCallback(
        (counterColorProperty: CounterColorProperty, hex: string, defaultColor: string) => {
            setCounter((prevCounterState) => {
                prevCounterState[counterColorProperty] = hex.toUpperCase() !== defaultColor.toUpperCase();
                return prevCounterState;
            });
        },
        [setCounter],
    );

    const setChangeLogoInCounterSettings = React.useCallback(
        (isChanged: boolean) => {
            setCounter((prevCounter) => {
                prevCounter.changeQRLogo = isChanged;
                return prevCounter;
            });
            if (isChanged) {
                sendParams({
                    [QRVisitParams.ChangeQRLogo]: 'yes',
                });
            }
        },
        [setCounter],
    );

    const handleChangeForegroundColor = React.useCallback(
        (hex: string) => {
            setForegroundColor(hex);

            setChangeColorInCounterSettings(CounterColorProperty.ChangeForegroundColor, hex, defaultQRCodeColor);
        },
        [setChangeColorInCounterSettings],
    );

    const handleChangeBackgroundColor = React.useCallback(
        (hex: string) => {
            setBackgroundColor(hex);

            setChangeColorInCounterSettings(
                CounterColorProperty.ChangeBackgroundColor,
                hex,
                defaultQRCodeBackgroundColor,
            );
        },
        [setChangeColorInCounterSettings],
    );

    const handleChangeQrSVG = React.useCallback((newQR: string) => {
        setQrSVG(newQR);
    }, []);

    const getFileMetaData = React.useCallback(async (file: File) => {
        if (file.type.match(/image/g)) {
            const { width, height } = await getImageHeightAndWidth(file);

            return {
                size: file.size,
                type: file.type,
                width,
                height,
            };
        }

        return {
            size: file.size,
            type: file.type,
        };
    }, []);

    const handleChangeLogo = React.useCallback(
        async (file: File | undefined) => {
            if (file) {
                try {
                    const fileMetaData = await getFileMetaData(file);
                    const error = getLogoValidationErrors(fileMetaData);
                    if (error.length) {
                        setLogoError(error);
                        setCurrentLoadedFileName(file.name);

                        return;
                    }
                } catch (error) {
                    setLogoError([LogoErrors.ReadingError]);
                    setCurrentLoadedFileName(file.name);

                    return;
                }

                const reader = new FileReader();
                reader.readAsBinaryString(file);

                reader.onload = function () {
                    if (typeof reader.result === 'string') {
                        setLogo({
                            logoBase64: `data:${file.type};base64,${btoa(reader.result)}`,
                            name: file.name,
                        });
                        setLogoError([]);
                        setCurrentLoadedFileName(file.name);

                        setChangeLogoInCounterSettings(true);
                    }
                };
            } else {
                setLogo({
                    logoBase64: ``,
                    name: '',
                });
                setLogoError([]);
                setCurrentLoadedFileName('');

                setChangeLogoInCounterSettings(false);
            }
        },
        [getFileMetaData, setChangeLogoInCounterSettings],
    );

    return (
        <Layout isMobile={isMobile}>
            <Wrapper isMobile={isMobile}>
                <SettingsBlock isMobile={isMobile}>
                    <Content isMobile={isMobile}>
                        <Header onClickInfo={handleClickInfo} />
                        <QRUrlInput onChangeUrl={handleChangeQRUrlInput} />
                        <CheckBoxWithTooltip
                            isChecked={isYQREnabled}
                            onChange={handleChangeChecked}
                            tooltip={<YQrIdTooltip />}
                        />
                        <ExpandedSettings
                            foregroundColor={foregroundColor}
                            onChangeForegroundColor={handleChangeForegroundColor}
                            backgroundColor={backgroundColor}
                            onChangeBackgroundColor={handleChangeBackgroundColor}
                            logoError={logoError}
                            logo={logo}
                            onChangeLogo={handleChangeLogo}
                            currentLoadedFileName={currentLoadedFileName}
                            scrollContainerId={bodyWrapperId}
                        />
                    </Content>
                    {!isMobile && <SaveGroupButtons url={url} qrSVGBase64={qrSVG} />}
                </SettingsBlock>
                <Preview
                    url={url}
                    foregroundColor={foregroundColor}
                    backgroundColor={backgroundColor}
                    logo={logo}
                    onChangeQrSVG={handleChangeQrSVG}
                    isYQREnabled={isYQREnabled}
                />
            </Wrapper>
            {isMobile && <SaveGroupButtons url={url} qrSVGBase64={qrSVG} />}
            <AboutQR isModalVisible={isModalVisible} onCloseInfo={handleCloseInfo} rows={PopupInfoRows} />
        </Layout>
    );
};
