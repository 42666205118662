import * as React from 'react';
import {
    ModalWrapper,
    Overlay,
    InfoHeader,
    HeaderText,
    ClearBackground,
    RelativeWrapper,
    ContentWrapper,
    HeaderWrapper,
    MobileDrawer,
} from './styles';
import { Close } from '../../images/close/Close';
import { ReactNode, useContext } from 'react';
import { customAlphabet } from 'nanoid';
import { yQrIdAlphabet } from '../../constants/yQRID';
import { QrContext } from '../../App';

export interface InfoDrawerProps {
    isVisible: boolean;
    header?: string | ReactNode;
    onClose: () => void;

    isFullScreen: boolean;
    isDisabledDrag?: boolean;
}

export const InfoDrawer: React.FC<InfoDrawerProps> = ({
    header,
    isVisible,
    onClose,
    children,
    isDisabledDrag = false,
    isFullScreen,
}) => {
    const nano = React.useCallback(customAlphabet(yQrIdAlphabet, 11), []);
    const id = React.useMemo(nano, [nano]);
    const { isMobile } = useContext(QrContext);

    const handleClick = React.useCallback(
        (evt: MouseEvent) => {
            if (evt.target instanceof Element) {
                const clickedElem = evt.target;
                if (clickedElem.closest(`[id='${id}']`)) {
                    return;
                }

                onClose();
            }
        },
        [id, onClose],
    );

    const handleKeyDown = React.useCallback(
        (evt) => {
            if (evt.key === 'Escape') {
                onClose();
            }
        },
        [onClose],
    );

    React.useEffect(() => {
        if (isVisible && !isMobile) {
            document.addEventListener('click', handleClick, true);
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('click', handleClick, true);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleClick, handleKeyDown, isMobile, isVisible]);

    return !isMobile ? (
        <>
            <Overlay isVisible={isVisible} />
            <ModalWrapper id={id} isVisible={isVisible}>
                <RelativeWrapper>
                    <ClearBackground onClick={onClose} isVisible={isVisible}>
                        <Close />
                    </ClearBackground>
                    <ContentWrapper>
                        {header && (
                            <InfoHeader>
                                <HeaderText typography="headline-m">{header}</HeaderText>
                            </InfoHeader>
                        )}
                        {children}
                    </ContentWrapper>
                </RelativeWrapper>
            </ModalWrapper>
        </>
    ) : (
        <MobileDrawer
            visible={isVisible}
            onClose={onClose}
            view="default"
            dragDisabled={isDisabledDrag}
            isFullScreen={isFullScreen}
        >
            {header && (
                <HeaderWrapper>
                    <HeaderText $isMobile={isMobile} typography="headline-s">
                        {header}
                    </HeaderText>
                </HeaderWrapper>
            )}
            {children}
        </MobileDrawer>
    );
};
