import styled from 'styled-components';

export const InfoContent = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    padding-left: ${({ isMobile }) => (isMobile ? `20px` : `36px`)};
    padding-right: ${({ isMobile }) => (isMobile ? `18px` : `40px`)};
    padding-top: 26px;
    padding-bottom: 24px;
`;
