import styled from 'styled-components';
import { Text } from '@yandex-lego/components/Text/bundle';

export const Wrapper = styled.div<{ isMobile: boolean }>`
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    width: 100%;

    height: ${({ isMobile }) => (isMobile ? `52px` : `43px`)};
    z-index: -1;
    flex-shrink: 0;

    margin-top: ${({ isMobile }) => isMobile && `23px`};
`;

export const FooterText = styled(Text)`
    color: #475a8080;
    text-align: end;
    margin-right: 11px;
`;

export const TextWrapper = styled.div<{ isMobile: boolean }>`
    border-top: 1px solid #b0bdd633;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: ${({ isMobile }) => (isMobile ? `center` : `end`)};
    justify-content: ${({ isMobile }) => (isMobile ? `center` : `flex-end`)};
    align-items: center;
`;
