import styled from 'styled-components';
import { ColorPicker } from '../ColorPicker/ColorPicker';

export const Wrapper = styled.div<{ isMobile: boolean }>`
    display: flex;

    flex-direction: ${({ isMobile }) => (isMobile ? `column` : `row`)};
    margin-top: ${({ isMobile }) => (isMobile ? `18px` : `28px`)};

    margin-bottom: ${({ isMobile }) => isMobile && `2px`};
    height: 100%;
`;

export const SingleColorPicker = styled(ColorPicker)<{ isMobile?: boolean }>`
    margin-top: ${({ isMobile }) => isMobile && `16px`};
`;
