import { qrSize } from '../../constants/QR';

export enum MimeType {
    Jpeg = 'image/jpeg',
    Png = 'image/png',
}

export interface useSvgToCanvasProps {
    canvas: HTMLCanvasElement;
    qrSVGBase64: string;
    width?: number;
    format: MimeType;
}

const jpegBackgroundDiff = 25;

export const svgToCanvasWithFormat = async ({ canvas, qrSVGBase64, width = qrSize, format }: useSvgToCanvasProps) => {
    return new Promise((resolve, reject) => {
        let canvasSize = format === MimeType.Jpeg ? width + jpegBackgroundDiff : width;
        if (!canvas) {
            reject('not valid canvas');
        }

        const context = canvas.getContext('2d');
        canvas.setAttribute('width', canvasSize.toString());
        canvas.setAttribute('height', canvasSize.toString());

        if (context && format === MimeType.Jpeg) {
            context.fillStyle = 'white';
            context.fillRect(0, 0, canvasSize, canvasSize);
        }

        const image = new Image();
        image.onload = () => {
            if (context) {
                const imagePosition = format === MimeType.Jpeg ? jpegBackgroundDiff / 2 : 0;
                context.drawImage(image, imagePosition, imagePosition);
                resolve(qrSVGBase64);
            } else {
                reject('no context');
            }
        };
        image.src = qrSVGBase64;
    });
};
