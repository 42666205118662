import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle<{ isMobile: boolean; isScrollEnabled: boolean }>`
  body {
    background: rgba(239, 242, 247, 1);
    max-width: 100%;
    height: 100%;
    overflow-y: ${({ isMobile, isScrollEnabled }) => isMobile && !isScrollEnabled && `hidden`};
    overflow-x: hidden;
    margin: 0;
  }

  html {
    height: 100%;
  }

  #root {
    height: 100%;
    max-width: 100%;
  }

`;

export const BodyWrapper = styled.div<{ isMobile: boolean; isScrollEnabled: boolean }>`
    height: 100%;
    min-height: ${({ isMobile }) => (isMobile ? `100%` : `580px`)};
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;

    overflow-y: ${({ isScrollEnabled, isMobile }) => isMobile && (isScrollEnabled ? `auto` : `hidden`)};
    -webkit-overflow-scrolling: ${({ isScrollEnabled }) => isScrollEnabled && `touch`};
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
`;

export const Wrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? `column` : `row`)};
    align-items: ${({ isMobile }) => isMobile && `center`};
    margin-top: ${({ isMobile }) => (isMobile ? `24px` : `68px`)};

    width: 100%;
    min-width: 256px;

    padding-left: ${({ isMobile }) => (isMobile ? `8px` : `50px`)};
    padding-right: ${({ isMobile }) => (isMobile ? `8px` : `50px`)};

    box-sizing: border-box;

    flex-shrink: 0;
    flex-grow: 1;
`;

export const SettingsBlock = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    margin-right: ${({ isMobile }) => !isMobile && `32px`};
    height: 100%;
    margin-bottom: 24px;
    width: ${({ isMobile }) => isMobile && `100%`};
`;

export const Content = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    width: ${({ isMobile }) => isMobile && `100%`};
`;

export const Link = styled.a`
    text-decoration: none;
    color: #4a8bff;

    cursor: pointer;

    &:focus-visible {
        outline: none;
    }
`;

export const Paragraph = styled.p`
    margin-bottom: 0;
    margin-top: 8px;
`;
